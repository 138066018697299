import { FragmentComponent } from '@/types/graphql'
import { TrustpilotRatingBlock } from './TrustpilotRatingBlock'
import { styled } from 'styled-components'
import { CarouselCardRecord } from 'types'
import { TypographyBlock } from './DesignSystem/Typography'
import { SimpleImageBlock } from './DesignSystem/SimpleImage'
import { SpacerBlock } from './DesignSystem/Spacer'
import { responsiveImageBlock } from '@/graphql/utils/commonBlocks'
import { AvatarImageBlock } from './AvatarImageBlock'
import { TrustpilotStarRating } from '../shared/TrustpilotStarRating'
import { TrustpilotStarRatingBlock } from './TrustpilotStarRatingBlock'

const fragment = `
  fragment CarouselCardFragment on CarouselCardRecord {
    id
    content {
      ...TypographyBlockFragment
      ...SimpleImageBlockFragment
      ...SpacerBlockFragment
      ...TrustpilotStarRatingBlockFragment
      ...AvatarImageBlockFragment
    }
  }

  ${TrustpilotStarRatingBlock.fragment}
  ${AvatarImageBlock.fragment}
`

const StyledCard = styled.div`
  background-color: var(--colour-surface-neutral);
  border-radius: var(--grid-16);
  padding: var(--grid-16);
  box-shadow: 0px 0px 20px 5px hsla(0, 0%, 0%, 0.01);
  max-width: 300px;
  height: 100%;
`

const renderBlock = (block: CarouselCardRecord['content'][0]) => {
  switch (block.__typename) {
    case 'TypographyRecord':
      return <TypographyBlock key={block.id} record={block} />
    case 'SimpleImageRecord':
      return <SimpleImageBlock key={block.id} record={block} />
    case 'SpacerRecord':
      return <SpacerBlock key={block.id} record={block} />
    case 'TrustpilotStarRatingRecord':
      return <TrustpilotStarRatingBlock key={block.id} record={block} />
    case 'AvatarImageRecord':
      return <AvatarImageBlock key={block.id} record={block} />
  }
}

export const CarouselCardBlock: FragmentComponent<{}, CarouselCardRecord> = ({ record }) => {
  return <StyledCard>{record.content.map(renderBlock)}</StyledCard>
}

CarouselCardBlock.fragment = fragment
CarouselCardBlock.recordName = 'CarouselCardRecord'
