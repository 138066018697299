import { Product, Products } from '@/lib/checkout/types'
import { getProduct } from '@/lib/checkout/products'

interface PriceByCountry {
  gb: Product
  us: Product
}

interface CurrentMembershipPrices {
  starter: PriceByCountry
  thrive: PriceByCountry
  thrivePlus: PriceByCountry
}

export const getCurrentPrices = (products: Products) => {
  const memberships: CurrentMembershipPrices = {
    starter: {
      gb: getProduct('GB_PROGRAM_4_MONTH_JAN24_TIER1_MONTHLY_ROLLING', products),
      us: getProduct('US_PROGRAM_4_MONTH_SEP24_TIER1_MONTHLY_ROLLING', products),
    },
    thrive: {
      gb: getProduct('GB_PROGRAM_12_MONTH_DEC23_TIER2_MONTHLY_ROLLING', products),
      us: getProduct('US_PROGRAM_12_MONTH_SEP24_TIER2_MONTHLY_ROLLING', products),
    },
    thrivePlus: {
      gb: getProduct('GB_PROGRAM_12_MONTH_OCT23_TIER3_MONTHLY_ROLLING', products),
      us: getProduct('US_PROGRAM_12_MONTH_SEP24_TIER3_MONTHLY_ROLLING', products),
    },
  }
  const kits: CurrentMembershipPrices = {
    starter: {
      gb: getProduct('GB_TEST_KIT_JAN24_TIER1_1_INSTALMENT', products),
      us: getProduct('US_TEST_KIT_SEP24_TIER1_1_INSTALMENT', products),
    },
    thrive: {
      gb: getProduct('GB_TEST_KIT_DEC23_TIER2_1_INSTALMENT', products),
      us: getProduct('US_TEST_KIT_SEP24_TIER2_1_INSTALMENT', products),
    },
    thrivePlus: {
      gb: getProduct('GB_TEST_KIT_OCT23_TIER3_1_INSTALMENT', products),
      us: getProduct('US_TEST_KIT_SEP24_TIER3_1_INSTALMENT', products),
    },
  }

  return { memberships, kits }
}
