import { DSForm } from '@/components/ds/Form/Form'
import { DSSpacer } from '@/components/ds/Spacer/Spacer'
import { DSText } from '@zoe/ds-web'
import { DSTextAndIcon } from '@/components/ds/TextAndIcon/TextAndIcon'
import { BrazeCustomEventName } from '@/lib/braze/brazeConstants'
import { registerBrazeUserWithEmail } from '@/lib/braze/brazeUtils'
import { trackSignupEvent } from '@/lib/gtm/gtmV2'
import * as Sentry from '@sentry/nextjs'
import { useState } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { D30WaitlistFormContent } from './D30WaitlistFormContent'

const StyledContainer = styled.div`
  border-radius: var(--radius-8);
  background-color: ${(props) => props.theme.v2.surface.cream100};
  padding: var(--grid-16);
  width: 100%;
`

interface D30WaitlistProps {
  title: string
  subtitle?: string
  brazeEventName: BrazeCustomEventName
  country?: string
  showMarketingConsent?: boolean
}

const validationSchema = Yup.object({
  firstName: Yup.string().required('Please fill in your name'),
  email: Yup.string().email('Email address does not look right').required('Please fill in your email'),
  marketingConsent: Yup.boolean(),
})

export const D30Waitlist = ({
  title,
  subtitle,
  brazeEventName,
  country,
  showMarketingConsent = true,
}: D30WaitlistProps) => {
  const [submitSuccess, setSubmitSuccess] = useState<boolean | undefined>()

  const handleSubmit = async (values: any) => {
    const marketingConsent = values.marketingConsent ?? false
    const additionalFields = {
      firstName: values.firstName,
      country: country,
    }

    try {
      await registerBrazeUserWithEmail(values.email, brazeEventName, marketingConsent, additionalFields)

      setSubmitSuccess(true)
      trackSignupEvent({
        email: values.email,
        section: 'd30',
        emailList: 'daily30_waitlist',
      })
    } catch (error) {
      Sentry.captureException(error)
      console.error('Error registering user with Braze', error)
      setSubmitSuccess(false)
    }
  }

  if (submitSuccess) {
    return (
      <StyledContainer>
        <DSTextAndIcon
          text={`You're on the list!`}
          textVariant="fluid-heading-500alt"
          textWeight="lotaRegular"
          textColour="primary"
          iconName="tick-in-star"
          iconColour="brand"
          iconPosition="left"
          iconSize={24}
        />
        <DSSpacer direction="vertical" size={8} />
        <DSText as="p" variant="fluid-paragraph-200">
          You&apos;ll receive an email when Daily30+ is available to buy.
        </DSText>
      </StyledContainer>
    )
  }

  if (submitSuccess === false) {
    return (
      <StyledContainer>
        <DSText as="h3" variant="fluid-heading-500alt">
          Something went wrong 😢
        </DSText>
        <DSSpacer direction="vertical" size={8} />
        <DSText as="p" variant="fluid-paragraph-200">
          Please refresh the page and try again.
        </DSText>
      </StyledContainer>
    )
  }

  return (
    <StyledContainer id="waitlist">
      <DSText as="h3" variant="fluid-heading-500alt">
        {title}
      </DSText>
      <DSSpacer direction="vertical" size={8} />
      <DSText as="p" variant="fluid-paragraph-200" weight="lotaLight">
        {subtitle}
      </DSText>

      <DSSpacer direction="vertical" size={12} />

      {submitSuccess === undefined && (
        <DSForm
          initialValues={{ name: '', email: '', marketingConsent: false }}
          onSubmit={handleSubmit}
          validateOnChange={true}
          validationSchema={validationSchema}
        >
          <D30WaitlistFormContent
            showMarketingConsent={showMarketingConsent}
            ctaText={country === 'US' ? 'Notify me' : 'Join the waitlist'}
          />
        </DSForm>
      )}
    </StyledContainer>
  )
}
