// context/ProductsContext.tsx
import React, { createContext, ReactNode, useContext } from 'react'
import { Products } from '@/lib/checkout/types'

type ProductsContextType = {
  products: Products
}

const ProductsContext = createContext<ProductsContextType | undefined>(undefined)

export const ProductsProvider: React.FC<{ children: ReactNode; products: Products }> = ({ children, products }) => {
  return <ProductsContext.Provider value={{ products }}>{children}</ProductsContext.Provider>
}

export const useProducts = (): Products => {
  const context = useContext(ProductsContext)
  if (context === undefined) {
    throw new Error('useProducts must be used within a ProductsProvider')
  }
  return context.products
}
