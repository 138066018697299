import { FragmentComponent } from '@/types/graphql'
import React from 'react'
import { useProducts } from '@/components/pages/membership/ProductsContext'
import { useCheckoutCountry } from '@/lib/hooks/useCheckoutCountry'
import { MembershipPdPPlansCards } from '@/elements/plans/Memberships/MembershipPriceCardPdp/MembershipPdPPlansCards'

export type MembershipPlanRecord = {
  __typename: 'MembershipPlanRecord'
  id?: string
}

const fragment = `
  fragment MembershipPlanFragment on MembershipPlanRecord {
    __typename
    id
  }
`

export const MembershipPlanBlock: FragmentComponent<{}, MembershipPlanRecord> = () => {
  const products = useProducts()
  const { checkoutCountry } = useCheckoutCountry()
  return checkoutCountry ? <MembershipPdPPlansCards products={products} country={checkoutCountry} /> : null
}

MembershipPlanBlock.fragment = fragment
MembershipPlanBlock.recordName = 'MembershipPlanRecord'
