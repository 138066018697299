import styled from 'styled-components'
import { DSBadge, DSSpacer, DSText, DSTextEm, TextColourType } from '@zoe/ds-web'

const StyledHeader = styled.div<{ $bgColour: string; $textColour: string }>`
  background-color: ${(props) => props.$bgColour};
  padding: var(--grid-16);
  position: relative;
`

const StyledTag = styled.div`
  position: absolute;
  right: var(--grid-16);
  top: var(--grid-16);
`

type MembershipPriceCardHeaderProps = {
  name: string
  commitmentMonths: number
  freeTrialDays?: number
  description: string
  bgColour: string
  textColour?: TextColourType
  tag?: string
}

export const MembershipPdpPriceCardHeader = ({
  name,
  commitmentMonths,
  freeTrialDays,
  description,
  bgColour,
  textColour = 'primary',
  tag,
}: MembershipPriceCardHeaderProps) => {
  return (
    <StyledHeader $bgColour={bgColour} $textColour={textColour}>
      <DSTextEm variant="fluid-heading-700" weight="nantes" as={'h3'} colour={textColour}>
        {name}
      </DSTextEm>
      <DSSpacer size={8} direction="vertical" />
      {tag && (
        <StyledTag>
          <DSBadge variant="tertiary">{tag}</DSBadge>
        </StyledTag>
      )}
      <DSBadge variant="neutral" rounded>
        {freeTrialDays ? `${freeTrialDays}-day free trial` : `${commitmentMonths}-month plan`}
      </DSBadge>
      <DSSpacer size={16} direction="vertical" />
      <DSText variant={'fluid-label-100'} weight="lotaLight" colour={textColour}>
        {description}
      </DSText>
    </StyledHeader>
  )
}
