import { useMemo } from 'react'
import { getBreakpointForWidth } from '@/components/ds/breakpoints'
import { useWindowSize } from '@/lib/hooks/useWindowSize'

export const useSetVisibility = (visibility: string[]) => {
  const window = useWindowSize()

  return useMemo(() => {
    const currentBreakpoint = getBreakpointForWidth(window.width ?? 0)

    return (
      (visibility.includes('small') && currentBreakpoint === 's') ||
      (visibility.includes('medium') && currentBreakpoint === 'm') ||
      (visibility.includes('large') && currentBreakpoint === 'l') ||
      (visibility.includes('extra_large') && currentBreakpoint === 'xl')
    )
  }, [visibility, window.width])
}
