import { formatCurrency } from '@/lib/checkout/utils'
import styled from 'styled-components'
import { DSFlex, DSIcon, DSSpacer, DSText } from '@zoe/ds-web'
import { Product } from '@/lib/checkout/types'

type MembershipPriceCardCostProps = { kit: Product; membership: Product }

const StyledSpacer = styled.div`
  align-self: center;
`

export const MembershipPdpPriceCardCost = ({ kit, membership }: MembershipPriceCardCostProps) => {
  return (
    <div>
      <DSSpacer size={16} direction="vertical" />
      <DSFlex direction="row" justifyContent="center">
        <DSFlex direction="column" justifyContent="center">
          <DSText variant="fluid-eyebrow-100">TESTING</DSText>
          <DSSpacer size={4} direction="vertical" />
          <DSText variant="fluid-heading-800" as="p" weight="nantes">
            {formatCurrency(kit.currency, kit.price, true)}
          </DSText>
        </DSFlex>
        <DSSpacer size={16} direction="horizontal" />
        <StyledSpacer>
          <DSIcon name="plus" size={16} />
        </StyledSpacer>
        <DSSpacer size={16} direction="horizontal" />

        <DSFlex direction="column" justifyContent="center">
          <DSText variant="fluid-eyebrow-100">MEMBERSHIP</DSText>
          <DSSpacer size={4} direction="vertical" />
          <DSText variant="fluid-heading-800" as="p" weight="nantes">
            {formatCurrency(membership.currency, membership.price, true)}
            <DSText variant="fluid-label-100" as="span" weight="lotaRegular">
              {' /month'}
            </DSText>
          </DSText>
        </DSFlex>
      </DSFlex>
      <DSSpacer size={16} direction="vertical" />
    </div>
  )
}
