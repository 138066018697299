import { DSFlex } from '@/components/ds/Flex/Flex'
import { DSText } from '@zoe/ds-web'
import styled from 'styled-components'
import { HomepagePricingCardRecord } from 'types'

const StyledPricingGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-areas:
    'testing_label gap membership_label'
    'testing_price plus membership_price';
  column-gap: var(--grid-12);
  row-gap: var(--grid-4);
  justify-content: start;
  text-align: left;
`

const PricingGridArea = styled.div<{ $areaId: string }>`
  ${({ $areaId }) => `grid-area: ${$areaId};`};
`

type HomepagePricingCardPricingGridProps = {
  record: HomepagePricingCardRecord
}

export const HomepagePricingCardPricingGrid = ({ record }: HomepagePricingCardPricingGridProps) => {
  return (
    <StyledPricingGrid>
      <PricingGridArea $areaId="testing_label">
        <DSText variant="fluid-label-100" colour={record.textColour}>
          TESTING
        </DSText>
      </PricingGridArea>
      <PricingGridArea $areaId="gap" />
      <PricingGridArea $areaId="membership_label">
        <DSText variant="fluid-label-100" colour={record.textColour}>
          MEMBERSHIP
        </DSText>
      </PricingGridArea>
      <PricingGridArea $areaId="testing_price">
        <DSText variant="fluid-heading-700" colour={record.textColour}>
          {record.testingPrice}
        </DSText>
      </PricingGridArea>
      <PricingGridArea $areaId="plus">
        <DSText variant="fluid-heading-700" colour={record.textColour}>
          +
        </DSText>
      </PricingGridArea>
      <PricingGridArea $areaId="membership_price">
        <DSFlex direction="row" justifyContent="flex-end" alignItems="baseline">
          <DSText variant="fluid-heading-700" colour={record.textColour}>
            {record.membershipPrice}
          </DSText>
          <DSText variant="fluid-label-100" colour={record.textColour}>
            /month
          </DSText>
        </DSFlex>
      </PricingGridArea>
    </StyledPricingGrid>
  )
}
