import styled from 'styled-components'

const StyledCard = styled.div`
  border-radius: 16px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 300px;
  overflow: hidden;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    margin: 0 2px 5px 2px;
    min-width: 275px;
    max-width: 275px;
  }
`

const StyledSeparator = styled.hr`
  border-top: 1px solid #e2e2e2;
  margin: 0 16px;
`

export type MembershipPriceCardProps = {
  header: React.ReactNode
  benefits: React.ReactNode
  cost: React.ReactNode
  cta: React.ReactNode
}

export const MembershipPdPPriceCard = ({ header, benefits, cost, cta }: MembershipPriceCardProps) => {
  return (
    <StyledCard>
      {header}
      {benefits}
      <StyledSeparator />
      {cost}
      <StyledSeparator />
      {cta}
    </StyledCard>
  )
}
