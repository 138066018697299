import { CheckoutCountry } from '@/lib/checkout/types'
import { formatCurrency, getCountryCurrency } from '@/lib/checkout/utils'
import { useCheckoutCountry } from '@/lib/hooks/useCheckoutCountry'
import Image from 'next/image'
import React from 'react'
import styled from 'styled-components'
import { DSText } from '@zoe/ds-web'
import { DSSpacer } from '@/components/ds/Spacer/Spacer'

export type MembershipBenefit = 'tests' | 'appAccess' | 'ghRestest4m' | 'ghRestest7m10m'

type MembershipPriceCardBenefitsProps = {
  additionalBenefits?: MembershipBenefit[]
}

type Benefit = {
  id: MembershipBenefit
  icon: string
  title: string
  included: boolean
  description?: string
  tag?: string
}

type ListItemWithIconProps = {
  benefit: Benefit
}

const GHR_PRICE_PER_COUNTRY: Record<CheckoutCountry, number> = {
  GB: 19900,
  US: 24900,
}

const getStarterBenefits = (country: CheckoutCountry): readonly Benefit[] => {
  const ghrPrice = GHR_PRICE_PER_COUNTRY[country]
  const currencyCode = getCountryCurrency(country)

  return [
    {
      id: 'tests',
      icon: '/assets/img/plans/21354/tests.svg',
      title: 'At-home test kit',
      description: 'Blood fat, blood sugar & gut health',
      included: true,
    },
    {
      id: 'appAccess',
      icon: '/assets/img/plans/21354/app-access.svg',
      title: 'ZOE app',
      description: 'Food scores, meal planning, step-by-step programs',
      included: true,
    },
    {
      id: 'ghRestest4m',
      icon: '/assets/img/plans/21354/gh-retest-single-grey.svg',
      title: 'Gut health retest at 4 months',
      tag: `Worth ${formatCurrency(currencyCode, ghrPrice, true)}`,
      included: false,
    },
    {
      id: 'ghRestest7m10m',
      icon: '/assets/img/plans/21354/gh-retest-double-grey.svg',
      title: 'Gut health retests at 8m & 12m',
      tag: `Worth ${formatCurrency(currencyCode, 2 * ghrPrice, true)}`,
      included: false,
    },
  ]
}

const StyledBenefits = styled.ul`
  list-style: none;
  margin: 0;
  padding: 16px;
`

const StyledBenefitItem = styled.li`
  align-items: center;
  color: ${(props) => props.theme.v2.onSurface.text};
  display: flex;
  font-family: ${(p) => p.theme.v2.font.lotaGrotesqueLight};
  justify-content: space-between;
  margin-bottom: var(--grid-24);

  &:last-of-type {
    margin-bottom: 0;
  }
`

const StyledImageAndText = styled.div`
  align-items: center;
  display: flex;
`

const StyledText = styled.div<{ $excluded: boolean }>`
  margin: 0 8px;

  .tag {
    margin-top: 4px;
  }

  .tag {
    background-color: ${(props) => (props.$excluded ? '#F7F7F7' : '#dbe4cb')}; /* not in design system */
    border-radius: 4px;
    color: ${(props) => (props.$excluded ? '#ABADB5' : '#242838')}; /* not in design system */
    display: inline-block;
    padding: 2px 4px;
  }
`

const ListItemWithIcon = ({ benefit }: ListItemWithIconProps) => {
  const textColour = benefit.included ? 'primary' : 'secondary-inverse'
  return (
    <StyledBenefitItem>
      <StyledImageAndText>
        <Image src={benefit.icon} width={32} height={32} alt={`${benefit.title} Icon`} />
        <StyledText $excluded={!benefit.included}>
          <DSText variant="fluid-paragraph-200" colour={textColour}>
            {benefit.title}
          </DSText>
          <DSSpacer size={2} direction="horizontal" />
          {benefit.description && (
            <DSText variant="fluid-label-100" weight="lotaLight" colour={textColour}>
              {benefit.description}
            </DSText>
          )}
          {benefit.tag && (
            <DSText variant="fluid-label-100" weight="lotaLight" className="tag">
              {benefit.tag}
            </DSText>
          )}
        </StyledText>
      </StyledImageAndText>
      <Image
        src={`/assets/img/icons/${benefit.included ? 'green-tick.svg' : 'cross-grey.svg'}`}
        width={16}
        height={16}
        alt={benefit.included ? 'Benefit Included' : ' Benefit Not included'}
      />
    </StyledBenefitItem>
  )
}

export const MembershipPdpPriceCardBenefits = ({ additionalBenefits = [] }: MembershipPriceCardBenefitsProps) => {
  const { checkoutCountry } = useCheckoutCountry()

  const ALL_BENEFITS = React.useMemo(() => {
    if (!checkoutCountry) {
      return []
    }

    return getStarterBenefits(checkoutCountry).map((originalBenefit) => {
      if (additionalBenefits.includes(originalBenefit.id)) {
        return {
          ...originalBenefit,
          icon: originalBenefit.icon.replace('-grey', ''),
          included: true,
        }
      }
      return originalBenefit
    })
  }, [additionalBenefits, checkoutCountry])

  return (
    <StyledBenefits>
      {ALL_BENEFITS.map((benefit) => (
        <ListItemWithIcon key={benefit.id} benefit={benefit} />
      ))}
    </StyledBenefits>
  )
}
