import { FragmentComponent } from '@/types/graphql'
import Carousel from '../pages/homepage2024/content/carousel/Carousel'
import { CarouselCardBlock } from './CarouselCardBlock'
import { CarouselBlockRecord } from 'types'

const fragment = `
  fragment CarouselBlockFragment on CarouselRecord {
    __typename
    id
    theme
    mobileTheme
    gap
    content {
      __typename
      ...CarouselCardFragment
    }
  }

  ${CarouselCardBlock.fragment}
`

export const CarouselBlock: FragmentComponent<{}, CarouselBlockRecord> = ({ record }) => {
  const content = record.content.map((item) => {
    switch (item.__typename) {
      case 'CarouselCardRecord':
        return <CarouselCardBlock key={item.id} record={item} />
    }
  })

  return <Carousel content={content} theme={record.theme} mobileTheme={record.mobileTheme} gap={record.gap} />
}

CarouselBlock.fragment = fragment
CarouselBlock.recordName = 'CarouselRecord'
