import { DSIcon, IconName, IconSize } from '@zoe/ds-web'
import React from 'react'
import styled from 'styled-components'

interface TypographyIconProps {
  name: IconName
  size: IconSize
}

const StyledTypographyIcon = styled.span`
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  margin: 0 0.25em;
`

export const TypographyIcon = ({ name, size }: TypographyIconProps) => (
  <StyledTypographyIcon
    style={{
      display: 'inline-flex',
      alignItems: 'center',
      verticalAlign: 'middle',
      margin: '0 0.25em',
    }}
  >
    <DSIcon name={name} size={size ?? 16} />
  </StyledTypographyIcon>
)
