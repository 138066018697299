import { FragmentComponent } from '@/types/graphql'
import { TrustpilotStarRatingRecord } from 'types'
import { TrustpilotStarRating } from '../shared/TrustpilotStarRating'
import { styled } from 'styled-components'

const fragment = `
  fragment TrustpilotStarRatingBlockFragment on TrustpilotStarRatingRecord {
    __typename
    id
    rating
  }
`

const StyledStarsContainer = styled.div`
  /* scale 24px -> 20px */
  transform: scale(0.8333333333) translateX(-10px);
  width: 107px;
`

export const TrustpilotStarRatingBlock: FragmentComponent<{}, TrustpilotStarRatingRecord> = ({ record }) => {
  return (
    <StyledStarsContainer>
      <TrustpilotStarRating rating={record.rating} noStyle />
    </StyledStarsContainer>
  )
}

TrustpilotStarRatingBlock.fragment = fragment
TrustpilotStarRatingBlock.recordName = 'TrustpilotStarRatingRecord'
