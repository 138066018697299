import { DSSpacer } from '@/components/ds/Spacer/Spacer'
import { LoadingSpinner } from '@/components/shared/LoadingSpinner'
import { useD30CheckoutCountry } from '@/lib/hooks/useD30CheckoutCountry'
import { useMemo } from 'react'
import { trackD30PDPViewEvent } from '@/lib/gtm/gtmV2'
import { D30NonGBWaitlist } from '../product-details-page/waitlist/D30NonGBWaitlist'
import { D30NoStockWailtist } from '../product-details-page/waitlist/D30NoStockWaitlist'
import { D30PriceSelectorBlock } from './D30PriceSelectorBlock'
import { FragmentComponent } from '@/types/graphql'
import { CHECKOUT_GB, CheckoutCountry } from '@/lib/checkout/types'

const fragment = `
  fragment D30AvailabilityCheckerBlockFragment on D30AvailabilityCheckerBlockRecord {
    __typename
    id
    pricingCardsConfig
    outOfStock
    hidePricingCards
  }
`

export const D30AvailabilityCheckerBlock: FragmentComponent<{}, any> = ({ record }) => {
  const isOutOfStock = record?.outOfStock || process.env.NEXT_PUBLIC_D30_PRODUCT_OUT_OF_STOCK === 'true'
  const hidePricingCards = record?.hidePricingCards

  const { isReady, isValidD30Country, country } = useD30CheckoutCountry()

  const Waitlist = useMemo(() => {
    if (isReady && country.length && !isValidD30Country) {
      trackD30PDPViewEvent({ productName: '', productPrice: 0, itemId: '', pageName: 'pdp_not_in_your_country' })
      return D30NonGBWaitlist
    }

    if (isReady && isOutOfStock) {
      trackD30PDPViewEvent({ productName: '', productPrice: 0, itemId: '', pageName: 'pdp_out_of_stock' })
      return D30NoStockWailtist
    }

    return null
  }, [isReady, isOutOfStock, isValidD30Country, country])

  if (!isReady) {
    return <LoadingSpinner />
  }

  return (
    <>
      {Waitlist && (
        <>
          <DSSpacer size={24} direction="vertical" />
          <Waitlist country={country} />
        </>
      )}
      <DSSpacer size={24} direction="vertical" />
      {!Waitlist && isValidD30Country && !hidePricingCards && (
        <D30PriceSelectorBlock
          disabled={!isValidD30Country || isOutOfStock}
          config={record?.pricingCardsConfig}
          country={country as CheckoutCountry}
        />
      )}
    </>
  )
}

D30AvailabilityCheckerBlock.fragment = fragment
D30AvailabilityCheckerBlock.recordName = 'D30AvailabilityCheckerBlockRecord'
