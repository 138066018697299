import { DSButton } from '@/components/ds/Button/Button'
import { DSModal } from '@zoe/ds-web'
import { DSSpacer } from '@/components/ds/Spacer/Spacer'
import { DSText } from '@zoe/ds-web'
import { useState } from 'react'
import { USNutritionalContentModal } from './USNutritionalContentModal'

export const USIngredientFAQBody = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <DSText variant="fluid-paragraph-200" weight="lotaLight">
        You can see the full nutritional information{' '}
        <DSButton
          colourScheme="default"
          onPress={() => setIsModalOpen(true)}
          size="large"
          variant="tertiary"
          width="auto"
        >
          here.
        </DSButton>
      </DSText>
      <DSSpacer size={24} direction="vertical" />
      <DSText variant="fluid-paragraph-200" weight="lotaLight">
        Ingredients:
      </DSText>
      <DSText variant="fluid-paragraph-200" weight="lotaLight">
        Seeds: (flaxseed, chia seed, sunflower seed, pumpkin seed, hemp seed), red lentil flakes, grape seed, nuts:
        (almond, hazelnut, walnut), inulin fibre, quinoa puffed, spice mix: ( white mushroom, thyme, onion, parsley,
        turmeric, cumin, rosemary, garlic), mushroom mix: ( Lions mane, Reishi, Chaga, Shiitake, Cordyceps, Maitake,
        Tremella), red beet flakes, carrot flakes, nutritional yeast flakes, baobab, buckthorn.
      </DSText>

      <DSModal
        isOpen={isModalOpen}
        variant="daily30"
        onBackdropClick={() => setIsModalOpen(false)}
        onCloseButtonClick={() => setIsModalOpen(false)}
      >
        <USNutritionalContentModal />
      </DSModal>
    </>
  )
}
