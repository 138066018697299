import { styled } from 'styled-components'
import { DatoImage } from '../elements/global/DatoImage'
import { responsiveImageBlock } from '@/graphql/utils/commonBlocks'
import { FragmentComponent } from '@/types/graphql'
import { AvatarImageRecord } from 'types'

const fragment = `
  fragment AvatarImageBlockFragment on AvatarImageRecord {
    __typename
    id
    avatarImage {
      ${responsiveImageBlock({ width: 48 })}
    }
  }
`

const StyledAvatarContainer = styled.div<{ $rounded?: boolean }>`
  display: flex;
  justify-content: center;
  width: 48px;

  img {
    border-radius: 100%;
  }
`

export const AvatarImageBlock: FragmentComponent<{}, AvatarImageRecord> = ({ record }) => {
  return (
    <StyledAvatarContainer>
      <DatoImage data={record.avatarImage.responsiveImage} />
    </StyledAvatarContainer>
  )
}

AvatarImageBlock.fragment = fragment
AvatarImageBlock.recordName = 'AvatarImageRecord'
