import styled from 'styled-components'
import { TrustpilotStarRating } from '@/components/shared/TrustpilotStarRating'
import { TrustpilotLogo } from '@/components/shared/TrustpilotLogo'
import { DSFlex } from '@/components/ds/Flex/Flex'
import { DSText, DSTextStrong } from '@zoe/ds-web'
import { DSSpacer } from '@/components/ds/Spacer/Spacer'

const StyledContainer = styled.div`
  width: 100%;
  text-align: center;

  p {
    color: ${(props) => props.theme.v2.onSurface.subduedText};
    font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
    font-size: ${(props) => props.theme.v2.typography.body.xs.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.xs.lineHeight};
    margin: 0;
    text-align: center;
  }

  div {
    display: inline-block;
  }
`

const StyledHomepageStarsContainer = styled.div`
  /* scale 24px -> 20px */
  transform: scale(0.8333333333) translateX(-10px);
  width: 107px;
`

export const Trustpilot = ({ oneLine = false }: { oneLine?: boolean }) => {
  if (oneLine) {
    return (
      <DSFlex direction="row" alignItems="center">
        <DSTextStrong variant="fluid-label-100" align="center">
          {process.env.TRUSTPILOT_RATING_TEXT}
        </DSTextStrong>
        <DSSpacer size={16} direction="horizontal" />
        <TrustpilotStarRating rating={parseFloat(process.env.TRUSTPILOT_RATING_SCORE || '4.3')} noStyle={true} />
        <DSSpacer size={16} direction="horizontal" />
        <TrustpilotLogo size="large" margin="none" />
      </DSFlex>
    )
  }

  return (
    <StyledContainer>
      <p>
        We are rated <strong>{process.env.TRUSTPILOT_RATING_TEXT}</strong>.
      </p>
      <TrustpilotStarRating rating={parseFloat(process.env.TRUSTPILOT_RATING_SCORE || '4.3')} />
      <div style={{ display: 'block', margin: '10px' }}>
        <TrustpilotLogo size="large" />
      </div>
    </StyledContainer>
  )
}

export const HomepageTrustpilot = () => {
  return (
    <DSFlex direction="row" alignItems="center">
      <DSSpacer size={12} direction="horizontal" />
      <StyledHomepageStarsContainer>
        <TrustpilotStarRating rating={parseFloat(process.env.TRUSTPILOT_RATING_SCORE || '4.3')} noStyle={true} />
      </StyledHomepageStarsContainer>
      <DSSpacer size={12} direction="horizontal" />
      <TrustpilotLogo size="medium" margin="none" />
    </DSFlex>
  )
}

export const TrustpilotRatingNoLogo = () => {
  return (
    <DSFlex direction="row" alignItems="center">
      <TrustpilotStarRating rating={parseFloat(process.env.TRUSTPILOT_RATING_SCORE || '4.3')} noStyle={true} />
      <DSSpacer size={16} direction="horizontal" />
      <DSText variant="fluid-label-100" align="center" weight="lotaLight">
        <strong>{process.env.TRUSTPILOT_RATING_TEXT}</strong> on Trustpilot
      </DSText>
      <DSSpacer size={16} direction="horizontal" />
    </DSFlex>
  )
}
