import { CheckoutCountry, Products } from '@/lib/checkout/types'
import styled from 'styled-components'
import { selectMembershipPdpOptions } from '@/elements/plans/Memberships/MembershipPriceCardPdp/selectMembershipPdpOptions'
import { MembershipPdPPriceCard } from '@/elements/plans/Memberships/MembershipPriceCardPdp/MembershipPdPPriceCard'
import { DSFlex } from '@/components/ds/Flex/Flex'

const StyledOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--grid-24);
  -webkit-overflow-scrolling: touch; // Smooth scrolling on iOS
  overflow-x: scroll;
  padding: var(--grid-16);

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    overflow-x: scroll;
    justify-content: flex-start;
    margin-top: 0;
  }
`

type MembershipPlansCardsProps = {
  products: Products
  country: CheckoutCountry
}

export const MembershipPdPPlansCards = ({ products, country }: MembershipPlansCardsProps) => {
  const options = selectMembershipPdpOptions(products, country)
  return (
    <DSFlex direction="row" justifyContent="center">
      <StyledOptions>
        {options.map(({ id, header, benefits, cost, cta }) => (
          <MembershipPdPPriceCard key={id} header={header} benefits={benefits} cost={cost} cta={cta} />
        ))}
      </StyledOptions>
    </DSFlex>
  )
}
