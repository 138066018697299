import { DSLinkButton } from '@/components/ds/LinkButton/LinkButton'
import { DSText } from '@zoe/ds-web'
import { trackCtaClick } from '@/lib/gtm/gtmV2'
import { FragmentComponent } from '@/types/graphql'
import React, { useContext } from 'react'
import { DsButtonRecord } from 'types'
import { TrackingContext } from '@/context/trackingContext'
import { DSFlex, DSIcon, DSSpacer } from '@zoe/ds-web'

const fragment = `
  fragment DsButtonBlockFragment on DsButtonRecord {
    __typename
    id
    label
    url
    variant
    colourScheme
    size
    width
    mobileWidth
    rounded
    customTracking
    withArrow
  }
`

export const DsButtonBlock: FragmentComponent<{}, DsButtonRecord> = ({ record }) => {
  const { section } = useContext(TrackingContext)

  return (
    <DSLinkButton
      colourScheme={record?.colourScheme}
      size={record?.size}
      variant={record?.variant}
      width={record?.width}
      mobileWidth={record?.mobileWidth}
      dangerouslySet__rounded={record?.rounded}
      onPress={() => trackCtaClick(section || 'cms', record.customTracking ? record.customTracking : record.label)}
      href={record?.url}
    >
      <DSFlex direction="row" justifyContent="center" alignItems="center">
        <DSText as="span" variant="fluid-paragraph-200" weight="lotaSemiBold">
          {record?.label}
        </DSText>
        {record.withArrow && (
          <>
            <DSSpacer size={8} direction="horizontal" />
            <DSIcon name="arrow-right" size={16} />
          </>
        )}
      </DSFlex>
    </DSLinkButton>
  )
}

DsButtonBlock.fragment = fragment
DsButtonBlock.recordName = 'DsButtonBlock'
