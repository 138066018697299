import { DSText } from '@zoe/ds-web'
import { IngredientChain } from '../product-details-page/D30IngredientSection'
import { DSSpacer } from '@/components/ds/Spacer/Spacer'
import styled, { keyframes } from 'styled-components'
import { BREAKPOINTS } from '@/components/ds/breakpoints'

const fragment = `
  fragment D30IngredientListBlockFragment on D30IngredientListRecord {
    __typename
    id
  }
`

const slideInRight = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`

const slideInLeft = keyframes`
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
`

const StyledAnimatedRight = styled.div`
  animation: ${slideInRight} 160s linear infinite;
  width: 400%;

  @media screen and (max-width: ${BREAKPOINTS.m}) {
    animation: ${slideInRight} 60s linear infinite;
  }
  @media screen and (max-width: ${BREAKPOINTS.s}) {
    animation: ${slideInRight} 30s linear infinite;
  }
`

const StyledAnimatedLeft = styled.div`
  animation: ${slideInLeft} 160s linear infinite;
  width: 400%;
  position: absolute;
  top: 155px;

  @media screen and (max-width: ${BREAKPOINTS.m}) {
    animation: ${slideInLeft} 60s linear infinite;
  }
  @media screen and (max-width: ${BREAKPOINTS.s}) {
    animation: ${slideInLeft} 30s linear infinite;
  }
`

const StyledAnimationsContainer = styled.div`
  position: relative;
  height: 350px;
`

const d30IngredientSectionContent = {
  imageUrls: [
    '/assets/img/daily30/ingredients/chia.png',
    '/assets/img/daily30/ingredients/shiitake.png',
    '/assets/img/daily30/ingredients/parsley.png',
    '/assets/img/daily30/ingredients/beetroot.png',
    '/assets/img/daily30/ingredients/quinoa.png',
    '/assets/img/daily30/ingredients/hemp-seed.png',
    '/assets/img/daily30/ingredients/hazelnut.png',
    '/assets/img/daily30/ingredients/buckthorn.png',
    '/assets/img/daily30/ingredients/lions-mane.png',
    '/assets/img/daily30/ingredients/turmeric.png',
    '/assets/img/daily30/ingredients/maitake.png',
    '/assets/img/daily30/ingredients/chaga.png',
  ],
  ingredientList: [
    {
      title: '7 x fruit & veg',
      body: (
        <>
          <DSText as="p" variant="fluid-paragraph-200" weight="lotaLight">
            Fruits and vegetables, rich in vitamins and minerals, are essential components of a healthy diet. They’re
            also high in fibre, which promotes gut microbiome diversity, supports overall health, and helps prevent
            chronic diseases. Plants – especially colourful ones – are rich in polyphenols. These plant chemicals feed
            our gut bacteria and help protect against free radicals, which can damage our cells and drive inflammation.
          </DSText>
          <DSSpacer size={12} direction="vertical" />
          <DSText as="p" variant="fluid-paragraph-200" weight="lotaRegular">
            Contains: baobab, beetroot, buckthorn, carrot, chicory root (inulin), garlic, and onion
          </DSText>
        </>
      ),
      id: 'fruit-and-veg',
    },
    {
      title: '8 x mushrooms',
      body: (
        <>
          <DSText as="p" variant="fluid-paragraph-200" weight="lotaLight">
            Mushrooms are a nutritional powerhouse, and each species has a unique blend of beneficial compounds. They
            contain a range of key micronutrients, including vitamin D. They’re also a great source of protein and
            gut-friendly fibre. Mushrooms also produce ergothioneine, which scientists believe is an antioxidant with
            anti-inflammatory properties.
          </DSText>
          <DSSpacer size={12} direction="vertical" />
          <DSText as="p" variant="fluid-paragraph-200" weight="lotaRegular">
            Contains: chaga, cordyceps, lions mane, maitake, reishi, shiitake, tremella and white
          </DSText>
        </>
      ),
      id: 'mushrooms',
    },
    {
      title: '5 x herbs',
      body: (
        <>
          <DSText as="p" variant="fluid-paragraph-200" weight="lotaLight">
            Herbs and spices contain a range of compounds that help reduce inflammation. Thanks to their polyphenol
            content, they may also benefit the gut microbiome – evidence suggests that spice mixes can alter the
            composition of our gutflora.
          </DSText>
          <DSSpacer size={12} direction="vertical" />
          <DSText as="p" variant="fluid-paragraph-200" weight="lotaRegular">
            Contains: cumin, parsley, rosemary, thyme, and turmeric
          </DSText>
        </>
      ),
      id: 'herbs-and-spices',
    },
    {
      title: '6 x seeds',
      body: (
        <>
          <DSText as="p" variant="fluid-paragraph-200" weight="lotaLight">
            A mix of seeds provides essential nutrients like plant protein while adding texture and flavour. They also
            contain healthy fats called polyunsaturated fatty acids, like omega-3s, which benefit your brain, heart, and
            beyond.
          </DSText>
          <DSSpacer size={12} direction="vertical" />
          <DSText as="p" variant="fluid-paragraph-200" weight="lotaRegular">
            Contains: chia seed, flaxseed, grape seed, hemp seed, pumpkin seed, and sunflower seed
          </DSText>
        </>
      ),
      id: 'seeds',
    },
    {
      title: '3 x nuts',
      body: (
        <>
          <DSText as="p" variant="fluid-paragraph-200" weight="lotaLight">
            Nuts deliver vital nutrients, such as plant protein, vitamins, and fibre, while delivering their trademark
            crunch and delicious flavour. They also supply beneficial monounsaturated and polyunsaturated fats, which
            support your heart and overall health.
          </DSText>
          <DSSpacer size={12} direction="vertical" />
          <DSText as="p" variant="fluid-paragraph-200" weight="lotaRegular">
            Contains: almonds, hazelnuts, and walnuts
          </DSText>
        </>
      ),
      id: 'nuts',
    },
    {
      title: '2 x legumes & wholegrains',
      body: (
        <>
          <DSText as="p" variant="fluid-paragraph-200" weight="lotaLight">
            Wholegrains and legumes are rich in polyphenols, which are antioxidants and reduce inflammation. They’re
            also high in fibre, which promotes a healthy gut microbiome and good bowel function. Wholegrain intake is
            associated with several benefits to human health, including a reduced risk of diseases such as heart disease
            and type 2 diabetes.
          </DSText>
          <DSSpacer size={12} direction="vertical" />
          <DSText as="p" variant="fluid-paragraph-200" weight="lotaRegular">
            Contains: quinoa, and red lentils
          </DSText>
        </>
      ),
      id: 'legumes',
    },
  ],
  preTitle: `What's inside`,
  title: (
    <>
      Add over <em>30 plants</em> to your daily diet
    </>
  ),
}

const ingredientImageUrls = [
  '/assets/img/daily30/ingredients/chia.png',
  '/assets/img/daily30/ingredients/shiitake.png',
  '/assets/img/daily30/ingredients/parsley.png',
  '/assets/img/daily30/ingredients/beetroot.png',
  '/assets/img/daily30/ingredients/quinoa.png',
  '/assets/img/daily30/ingredients/hemp-seed.png',
  '/assets/img/daily30/ingredients/hazelnut.png',
  '/assets/img/daily30/ingredients/buckthorn.png',
  '/assets/img/daily30/ingredients/lions-mane.png',
  '/assets/img/daily30/ingredients/turmeric.png',
  '/assets/img/daily30/ingredients/maitake.png',
  '/assets/img/daily30/ingredients/chaga.png',
]

export const D30IngredientListBlock = () => {
  const midIndex = Math.ceil(ingredientImageUrls.length / 2)
  const firstHalf = ingredientImageUrls.slice(0, midIndex)
  const secondHalf = ingredientImageUrls.slice(midIndex)
  return (
    <StyledAnimationsContainer>
      <StyledAnimatedRight>
        <IngredientChain images={firstHalf} />
      </StyledAnimatedRight>
      <StyledAnimatedLeft>
        <IngredientChain images={secondHalf} />
      </StyledAnimatedLeft>
    </StyledAnimationsContainer>
  )
}

D30IngredientListBlock.fragment = fragment
D30IngredientListBlock.recordName = 'D30IngredientListRecord'
