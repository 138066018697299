import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { DSSpacer } from '@/components/ds/Spacer/Spacer'
import { CarouselProgressControl } from '@/components/pages/homepage2024/content/carousel/CarouselProgressControl'
import { trackCarouselEvent } from '@/lib/gtm/gtmV2'
import { TrackingContext } from '@/context/trackingContext'

export type CarouselTheme = 'neutral' | 'neutral-disabled' | 'teal'

interface CarouselProps {
  content: JSX.Element[]
  theme?: CarouselTheme
  mobileTheme?: CarouselTheme
  gap?: string
}

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 2000px;
  margin: 0 auto;
  overflow: hidden;
  outline: none;
`

const CarouselWrapper = styled.div<{ $centred: boolean; $gap?: string }>`
  display: flex;
  gap: ${({ $gap }) => ($gap ? `var(--grid-${$gap})` : 'var(--grid-16)')};
  justify-content: ${({ $centred }) => ($centred ? 'center' : 'flex-start')};

  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome */
  }
`

const CarouselItemContainer = styled.div`
  flex: 0 0 auto;
  width: auto;
  scroll-snap-align: start;
  outline: none;

  &:last-child {
    padding-right: 0;
  }
`

const Carousel: React.FC<CarouselProps> = ({ content, theme = 'neutral', mobileTheme, gap }) => {
  const { section } = useContext(TrackingContext)
  const [scrollProgress, setScrollProgress] = useState(0)
  const carouselRef = useRef<HTMLDivElement>(null)
  const [isFullWidth, setIsFullWidth] = useState(false)
  const [currentMainItem, setCurrentMainItem] = useState(0)

  const checkIfFullWidth = () => {
    const carousel = carouselRef.current
    if (carousel) {
      const isContentFullWidth = carousel.scrollWidth > carousel.clientWidth
      setIsFullWidth(isContentFullWidth)
    }
  }

  const handleScroll = () => {
    const carousel = carouselRef.current
    if (carousel) {
      const scrollPosition = carousel.scrollLeft
      const maxScroll = carousel.scrollWidth - carousel.clientWidth
      const progress = scrollPosition / maxScroll
      setScrollProgress(progress * (content.length - 1))
    }
  }

  useEffect(() => {
    const scrollProgressInt = Math.round(scrollProgress)
    if (scrollProgressInt !== currentMainItem) {
      setCurrentMainItem(scrollProgressInt)
      const currentTitle = content[scrollProgressInt]?.props?.title ?? `Carousel Element ${scrollProgressInt}`
      trackCarouselEvent(currentTitle, section)
    }
  }, [scrollProgress, currentMainItem])

  useEffect(() => {
    const carousel = carouselRef.current
    if (carousel) {
      carousel.addEventListener('scroll', handleScroll)
      return () => {
        carousel.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  useEffect(() => {
    checkIfFullWidth()

    const resizeObserver = new ResizeObserver(() => {
      checkIfFullWidth()
    })

    if (carouselRef.current) {
      resizeObserver.observe(carouselRef.current)
    }

    window.addEventListener('resize', checkIfFullWidth)

    const timeoutId = setTimeout(checkIfFullWidth, 100)

    return () => {
      resizeObserver.disconnect()
      window.removeEventListener('resize', checkIfFullWidth)
      clearTimeout(timeoutId)
    }
  }, [])

  return (
    <CarouselContainer>
      <CarouselWrapper ref={carouselRef} onScroll={handleScroll} $centred={!isFullWidth} $gap={gap}>
        {content.map((item, index) => (
          <CarouselItemContainer key={index}>{item}</CarouselItemContainer>
        ))}
      </CarouselWrapper>
      {isFullWidth && (
        <>
          <DSSpacer size={96} direction="vertical" breakAt={{ m: 48 }} />
          <CarouselProgressControl
            contentSize={content.length}
            scrollProgress={scrollProgress}
            carouselRef={carouselRef}
            theme={theme}
            mobileTheme={mobileTheme}
          />
        </>
      )}
    </CarouselContainer>
  )
}

export default Carousel
