import { DSAccordion } from '@zoe/ds-web'
import { DSFlex } from '@/components/ds/Flex/Flex'
import { DSIcon } from '@zoe/ds-web'
import { DSSpacer } from '@/components/ds/Spacer/Spacer'
import { DSText } from '@zoe/ds-web'
import { BREAKPOINTS } from '@/components/ds/breakpoints'
import { trackAccordionClickEvent } from '@/lib/gtm/gtmV2'
import React from 'react'
import styled, { keyframes } from 'styled-components'

const slideInRight = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`

const slideInLeft = keyframes`
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
`

const StyledAnimatedRight = styled.div`
  animation: ${slideInRight} 160s linear infinite;
  width: 400%;

  @media screen and (max-width: ${BREAKPOINTS.m}) {
    animation: ${slideInRight} 60s linear infinite;
  }
  @media screen and (max-width: ${BREAKPOINTS.s}) {
    animation: ${slideInRight} 30s linear infinite;
  }
`

const StyledAnimatedLeft = styled.div`
  animation: ${slideInLeft} 160s linear infinite;
  width: 400%;
  position: absolute;
  top: 155px;

  @media screen and (max-width: ${BREAKPOINTS.m}) {
    animation: ${slideInLeft} 60s linear infinite;
  }
  @media screen and (max-width: ${BREAKPOINTS.s}) {
    animation: ${slideInLeft} 30s linear infinite;
  }
`

const StyledAnimationsContainer = styled.div`
  position: relative;
  height: 350px;
`
const StyledContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  border-radius: var(--radius-24);
  background: var(--colour-surface-cream-subtle);
  overflow: hidden;
  padding: 0 32px;
`

const StyledAccordionContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
`

export const IngredientChain = ({ images = [] }: { images: string[] }) => {
  return (
    <DSFlex direction="row" alignItems="center">
      {/* The images are repeated 4 times so the effect is smoother */}
      {images
        .concat(images)
        .concat(images)
        .concat(images)
        .concat(images)
        .map((image, index) => (
          <React.Fragment key={index}>
            <img src={image} alt="" width={170} style={{ maxHeight: '170px' }} />
            <DSSpacer size={16} direction="horizontal" />
            <DSIcon name="plus" size={32} colour="lime" />
            <DSSpacer size={16} direction="horizontal" />
          </React.Fragment>
        ))}
    </DSFlex>
  )
}
export const D30IngredientSection = ({ imageUrls, ingredientList, title, preTitle }) => {
  const midIndex = Math.ceil(imageUrls.length / 2)
  const firstHalf = imageUrls.slice(0, midIndex)
  const secondHalf = imageUrls.slice(midIndex)

  return (
    <StyledContainer>
      <DSSpacer size={72} direction="vertical" breakAt={{ l: 48 }} />
      <DSFlex direction="column" alignItems="center">
        <DSText as="span" variant="fluid-paragraph-200" weight="lotaSemiBold">
          {preTitle}
        </DSText>
        <DSSpacer size={12} direction="vertical" />
        <DSText as="h2" variant="fluid-heading-700" weight="nantes" align="center">
          {title}
        </DSText>
      </DSFlex>
      <DSSpacer size={24} direction="vertical" />
      <StyledAnimationsContainer>
        <StyledAnimatedRight>
          <IngredientChain images={firstHalf} />
        </StyledAnimatedRight>
        <StyledAnimatedLeft>
          <IngredientChain images={secondHalf} />
        </StyledAnimatedLeft>
      </StyledAnimationsContainer>
      <DSSpacer size={24} direction="vertical" />
      <StyledAccordionContainer>
        <DSAccordion
          content={ingredientList}
          onSectionClick={(content) => {
            trackAccordionClickEvent({
              section: 'd30',
              linkPosition: preTitle,
              textOrImageUrl: content.title,
            })
          }}
        />
      </StyledAccordionContainer>
      <DSSpacer size={72} direction="vertical" breakAt={{ l: 48 }} />
    </StyledContainer>
  )
}

export default D30IngredientSection
